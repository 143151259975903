import { useEffect, useState } from "react";
import { StoreKeys } from "../../services/storage.service";

/**
 * A React hook to manage storage values in components.
 * @param key The key for the storage item.
 * @param onInit (Optional) A function to generate the initial value or a static value to render.
 * @returns A tuple containing the stored value and a function to update it.
 */
const useStorage = <T = any>(key: StoreKeys, onInit?: T): [T | undefined, (value: T) => void] => {
    // State to store our value
    const [storedValue, setStoredValue] = useState(() => {
      try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : onInit;
      } catch (error) {
        console.warn('Error parsing stored value:', error);
        return onInit;
      }
    });
  
    // Effect to update state on storage changes
    useEffect(() => {
      const storageChangeHandler = (event: StorageEvent) => {
        if (event.storageArea === window.localStorage && event.key === key) {
          try {
            const newValue = JSON.parse(event.newValue || 'null');
            setStoredValue(newValue);
          } catch (error) {
            console.warn('Error parsing new storage value:', error);
          }
        }
      };
  
      window.addEventListener('storage', storageChangeHandler);
  
      return () => window.removeEventListener('storage', storageChangeHandler);
    }, [key]);
  
    // Function to set value in localStorage
    const setValue = (value: T) => {
      try {
        const newValue = JSON.stringify(value);
        window.localStorage.setItem(key, newValue);
        setStoredValue(value);
      } catch (error) {
        console.warn('Error storing value:', error);
      }
    };
  
    return [storedValue, setValue];
  }

  export default useStorage;
  