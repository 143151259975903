import React, { useEffect, useState } from 'react';

import './RecentCallsView.css';
import { useApi } from '../../contexts/ApiContext';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export interface Meeting {
  id: string;
  external_id: string;
  title: string;
}

export interface Call {
  id: string;
  meeting: Meeting;
  title: string;
  start_timestamp: string;
  finish_timestamp: string;
}
export interface RecentCallsViewProps {
  onMeetingSelected?: (meeting: Call) => void;
}

export function RecentCallsView({ onMeetingSelected = () => undefined }: RecentCallsViewProps) {

  const [userMeetings, setUserMeetings] = useState<Call[]>([]);
  const [selectedMeeting, setSelectedMeeting] = useState<Call>();
  const [groupedMeetings, setGroupedMeetings] = useState<{ [key: string]: Call[] }>({});
  const { get, getUser } = useApi();
  
  const getUserMeetings = () => {
    const user = getUser();
    get(`calls/all?token=${user?.token}`)
      .then(res => {
        setUserMeetings(res.calls);

        const meeting = res.calls.find((m: Call) => m.id === window.location.hash.replace(/^#/, ''));
        meeting && setSelectedMeeting(meeting);

        if (!meeting && res.calls.length > 0) {
          setSelectedMeeting(res.calls[res.calls.length - 1]);
        }
      })
  }

  useEffect(() => {
    if (selectedMeeting) {
      window.location.hash = selectedMeeting?.id;
    }

    onMeetingSelected(selectedMeeting);
  }, [selectedMeeting]);


  useEffect(() => {
    const groupedUserMeetings: { [key: string]: Call[] } = {};

    userMeetings?.forEach(meeting => {
      const date = meeting.start_timestamp.split("T")[0];
      if (!groupedUserMeetings[date]) {
        groupedUserMeetings[date] = [];
      }
      groupedUserMeetings[date].push(meeting);
    });

    setGroupedMeetings(groupedUserMeetings);
  }, [userMeetings]);

  useEffect(() => {
    getUserMeetings();
  }, []);

  return (
    <div className='RecentCallsView p-4 flex flex-col gap-2 overflow-y-auto'>
      {Object.keys(groupedMeetings).reverse().map((date, key) => (
        <div key={key}>
          {!(new Date(date).toJSON().slice(0, 10) === new Date().toJSON().slice(0, 10)) && <p className="flex items-center mb-2">
            <span className="h-[1px] flex-1 bg-[#1F242F]"></span>
            <span className="px-2 text-sm text-[#CECFD2]">{dayjs(new Date(groupedMeetings[date][groupedMeetings[date].length - 1]?.start_timestamp)).fromNow()}</span>
            <span className="h-[1px] flex-1 bg-[#1F242F]"></span>
          </p>}
          {groupedMeetings[date].map(meeting => (
            <div onClick={() => setSelectedMeeting(meeting)} key={meeting.id} className={`px-3 py-2 flex items-center gap-2 cursor-pointer rounded mb-2 hover:bg-gray-800${selectedMeeting?.id === meeting.id ? ' bg-gray-800' : ''}`}>
              <div className={`indicator rounded-full ${meeting.finish_timestamp === null ? 'bg-[#FDB022]' : 'bg-green-600'}`}></div>
              <span className="font-medium whitespace-nowrap text-ellipsis overflow-hidden">{meeting.title}</span>
            </div>
          )).reverse()}
        </div>
      ))}
    </div>
  );
}
