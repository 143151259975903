import React, { useEffect, useRef, useState } from 'react';

import './TranscriptionCopyButton.scss';
import { ClipboardButton } from '../ClipboardButton';
import {TranscriptionEntryData} from "../TranscriptEntry";

export interface TranscriptionCopyButtonProps {
  transcriptList?: TranscriptionEntryData[];
  className?: string;
}

export function TranscriptionCopyButton({ transcriptList = [], className = '' }: TranscriptionCopyButtonProps) {
  const [copied, setCopied] = useState(false);
  const clipboardBtnRef = useRef<HTMLButtonElement>(null);

  const copyTranscriptions = () => {
    setCopied(true);
    const mergedTranscripts = transcriptList.map(transcript => {
      return `${transcript.speaker}: ${transcript.content}`;
    }).join('\n');
    navigator.clipboard.writeText(mergedTranscripts);
    const blob = new Blob([mergedTranscripts], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'transcriptions.txt';
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }

  useEffect(() => {
    const handleClipboardClick = (event: MouseEvent) => {
      event.stopPropagation();
    };

    const clipboardButton = clipboardBtnRef.current;
    if (clipboardButton) {
      clipboardButton.addEventListener('click', handleClipboardClick);
    }

    return () => {
      if (clipboardButton) {
        clipboardButton.removeEventListener('click', handleClipboardClick);
      }
    };
  }, []);

  return (
    <div onClick={copyTranscriptions} className={`TranscriptionCopyButton flex gap-2 items-center bg-[#121824] border border-[#333741] hover:bg-[#293347] disabled:bg-[#4c4c4d] p-2 cursor-pointer ${className}`}>
      <ClipboardButton clipboardRef={clipboardBtnRef} />
      <p>{copied ? 'Exported Transcriptions!' : 'Export Transcript'}</p>
    </div>
  );
}
