import React, { useEffect, useRef, useState } from 'react';

import './TranscriptList.scss';
import {TranscriptEntry, TranscriptionEntry, TranscriptionEntryData} from '../TranscriptEntry';
import { Call, Meeting } from '../RecentCallsView';
import { useApi } from '../../contexts/ApiContext';
import { toast } from 'react-toastify';
import { TranscriptionCopyButton} from '../TranscriptionCopyButton';
import {AssistantSuggestions} from "../AssistantSuggestions";


export interface ActionButtonsResponse {
  total: number
  buttons?: ActionButton[]
}

export interface ActionButton {
  name: string;
  type: string;
  prompt: string;
}

export interface TranscriptListProps {
  call?: Call;
  onActionButtonClicked?: (ab: ActionButton) => void;
  onAssistantRequest?: (message: string) => void,
}

export function TranscriptList({
  call,
  onActionButtonClicked = (ab: ActionButton) => {},
  onAssistantRequest = (message: string) => {},
}: TranscriptListProps) {

  const [transcripts, setTranscripts] = useState<TranscriptionEntry[]>([]);
  const [actionButtons, setActionButtons] = useState<(ActionButton)[]>();
  const [currentMeeting, setCurrentMeeting] = useState<Call>();
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
  const lastEntryRef = useRef<HTMLDivElement>(null);

  const { get, getUser } = useApi();

  const getTranscripts = () => {
    setLoadingMessages(true);
    get(`transcription?meeting_session_id=${currentMeeting?.id}`)
      .then(res => {
        setLoadingMessages(false);
        setTranscripts(res.map(r => new TranscriptionEntry(r)));
      }, err => {
        setLoadingMessages(false);
        toast('An error occured. Try again', { type: 'error' })
      });
  }

  useEffect(() => {
    if (lastEntryRef.current) {
      lastEntryRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [transcripts]);

  useEffect(() => {
    setCurrentMeeting(call);
  }, [call]);

  const fetchActionButtons = function () {
    get(`assistant/buttons?meeting_session_id=${currentMeeting?.id}`)
      .then((response: ActionButtonsResponse) => {
        setActionButtons(response.buttons);
      });
  }

  useEffect(() => {
    if (currentMeeting?.meeting?.external_id) {
      getTranscripts();
      fetchActionButtons();
    }
  }, [currentMeeting]);



  const clickedInsideTranscriptList = (e) => {
    if ((e.target as HTMLElement).tagName === 'B') {
      onAssistantRequest(e.target.innerText);
    }
  }

  return (
    <div className='TranscriptList relative flex flex-col max-h-full w-full h-full overflow-hidden group/transcript-container' onClick={clickedInsideTranscriptList}>
      {transcripts.length ? <div className="overflow-y-auto">
        <div className="mr-2 hidden group-hover/transcript-container:flex absolute top-2 z-50 w-[fit-content]">
          <TranscriptionCopyButton className='rounded-lg' transcriptList={transcripts} />
        </div>
        {transcripts.map((transcript, index) => (
          <div ref={transcripts.length - 1 === index ? lastEntryRef : null} key={index}>
            <TranscriptEntry entry={transcript} speaker_id={transcript.speaker_id} timestamp={transcript.timestamp} text={transcript.content} speaker={transcript.speaker} />
          </div>
        ))
        }
      </div> : <div className='flex items-center justify-center flex-grow overflow-hidden'><span>{loadingMessages ? 'Loading your transcriptions history...' : 'No transcriptions'}</span></div>}

      <AssistantSuggestions suggestions={actionButtons} selectSuggestion={onActionButtonClicked}/>
    </div>
  );
}
