import React from 'react';

import './RequestAccess.scss';
import { VexaLogo } from '../VexaLogo';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { MessageType } from '../../../services/message-listener.service';
import { sendMessage } from '../../helpers/in-content-messaging.helper';
import starIcon from "../../../assets/images/svg/black-star.svg";
import whiteStarIcon from "../../../assets/images/svg/white-star.svg";
import { useApi } from '../../contexts/ApiContext';

export interface RequestAccessProps {
  prop?: string;
}

export function RequestAccess({ }: RequestAccessProps) {
  const { post, getUser } = useApi();

  return (
    <div className='RequestAccess flex h-screen w-screen overflow-hidden bg-[#0c111d]'>
      <div className="w-full flex items-center justify-center flex-col lg:w-2/5">
        <div className="flex flex-col max-w-[400px] m-auto p-5">
          <VexaLogo className='mb-20' />
          <h4 className='font-semibold text-4xl mb-3 text-[#F5F5F6]'>Request Access</h4>
          <p className='text-base font-normal mb-8 text-[#94969C]'>Kindly contact Vexa support to request access to use the extension</p>
        </div>

        <div className="p-8 w-full">
          <p className='text-sm text-[#94969C] font-normal'>&copy; Vexa {new Date().getFullYear()}</p>
        </div>

      </div>
      <div className="w-3/5 hidden lg:flex flex-col p-20 bg-[#1F242F] text-[#F5F5F6]">
        <h3 className='font-medium text-3xl'>
          “Great transcription and excellent AI features. Saves us a ton of time notetaking for meetings”
        </h3>

        <div className='mb-24'>
          <div className="flex mt-6">
            <div className='font-semibold text-lg'>
              <p>&mdash; Aliah Lane</p>
              <p className='font-medium text-base text-[#94969C]'>Founder, Layers.io</p>
            </div>
            <div className="flex ml-auto gap-1">
              <img className='h-5' src={whiteStarIcon} alt="star icon" />
              <img className='h-5' src={whiteStarIcon} alt="star icon" />
              <img className='h-5' src={whiteStarIcon} alt="star icon" />
              <img className='h-5' src={whiteStarIcon} alt="star icon" />
              <img className='h-5' src={whiteStarIcon} alt="star icon" />
            </div>
          </div>
        </div>

        <div className='w-full rounded-lg overflow-hidden mx-auto'>
          <iframe className='' width='100%' height="454px"
            allowFullScreen
            src="https://www.youtube.com/embed/30yNRO1X7wY">
          </iframe>
        </div>


      </div>
    </div>
  );
}
