import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Routes, Route, Navigate } from 'react-router';
import { GuardMiddleware, GuardConfigProvider, GuardProvider } from 'react-router-guarded-routes';
import { useApi } from './shared/contexts/ApiContext';
import { Dashboard, SignIn } from './shared/components';
import { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { RequestAccess } from './shared/components/RequestAccess/RequestAccess';

function App() {
  const { getUser, setUser } = useApi();
  const navigate = useNavigate();
  
  const requireAuthed: GuardMiddleware = {
    handler: (to, from, next) => {
      if (getUser()) {
        next();
      } else {
        next('/signin');
      }
    },
    register: (to, from) => {
      return true;
    },
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams.has('token')) {
      const token = url.searchParams.get('token');
      if (token) {
        setUser({
          token,
          domain: '',
        }, true);
        url.searchParams.delete('token');
        window.location.href = url.href;
      }
    }
  }, [setUser]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ''}>
      <GuardConfigProvider>
        <GuardProvider
          fallback={<Navigate to='/signin' />}
          guards={[requireAuthed]}
        >
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/request-access" element={<RequestAccess />} />
            {/* <Route path="/signin" element={<SignIn />} /> */}
          </Routes>
        </GuardProvider>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
        </Routes>
      </GuardConfigProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
