import React from 'react';
import './TranscriptEntry.scss';
import { CopyButton } from '../CopyButton';

export interface TranscriptEntryProps {
  entry: TranscriptionEntry;
  speaker: string;
  speaker_id: string;
  text: string;
  timestamp: string;
}

export interface TranscriptionEntryData {
  content: string;
  html_content: string;
  html_content_short: string;
  keywords: string[];
  speaker: string;
  speaker_id: string;
  timestamp: string;
  mode: TranscriptionEntryMode;
}

export enum TranscriptionEntryMode {
  None,
  Content,
  HtmlContent,
  HtmlContentShort
}

export class TranscriptionEntry implements TranscriptionEntryData {
  content: string;
  html_content: string;
  html_content_short: string;
  keywords: string[];
  speaker: string;
  speaker_id: string;
  timestamp: string;
  mode: TranscriptionEntryMode = TranscriptionEntryMode.None;

  constructor({
    content = null,
    html_content = null,
    html_content_short = null,
    keywords = null,
    speaker = null,
    speaker_id = null,
    timestamp = null,
  } = {}) {
    this.content = content;
    this.html_content = html_content;
    this.html_content_short = html_content_short;
    this.keywords = keywords;
    this.speaker = speaker;
    this.speaker_id = speaker_id;
    this.timestamp = timestamp;
  }

  getContentFor(globalMode: TranscriptionEntryMode = TranscriptionEntryMode.HtmlContent) {
    const mode = this.mode || globalMode;

    switch (mode) {
      case TranscriptionEntryMode.HtmlContentShort:
        return this.html_content_short || this.content;
      case TranscriptionEntryMode.HtmlContent:
        return this.html_content || this.content;
    }

    return this.content;
  }

}

// Function to format the timestamp
function formatDateString(timestamp: string): string {
  if(!timestamp) {
    return '';
  }
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  return `${hours}:${formattedMinutes}:${formattedSeconds}`;
}


export function TranscriptEntry({entry, speaker, text, timestamp}: TranscriptEntryProps) {
  const formattedTimestamp = formatDateString(timestamp);

  const copyTranscript = () => {
    navigator.clipboard.writeText(text);
  }

  return (
    <div className='TranscriptEntry my-2 mr-2'>
      <div className="flex flex-col p-3 text-[#CECFD2] rounded-[10px] border border-[#1F242F] bg-[#161B26] relative group">
        <span className="sticky top-2 z-10 group-hover:block hidden">
          <span className='absolute top-0 right-0'>
            <CopyButton onClick={copyTranscript}/>
          </span>
        </span>
        <div className='flex gap-2 mb-1 break-words items-center'>
          <span className="font-semibold text-white select-text break-words">{speaker === 'TBD' ? '' : speaker}</span><span className='items-center text-xs select-text break-words'>{formattedTimestamp}</span>
        </div>
        <p className='break-words select-text' dangerouslySetInnerHTML={{__html: entry.getContentFor()}}></p>
      </div>
    </div>
  );
}
