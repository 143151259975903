import React, { useEffect, useState } from 'react';

import './VexaToolbar.css';
import { VexaLogo } from '../VexaLogo';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useApi } from '../../contexts/ApiContext';
import { toast } from 'react-toastify';
import { sendMessage } from '../../helpers/in-content-messaging.helper';
import { MessageType } from '../../../services/message-listener.service';

export interface VexaToolbarProps { }

export function VexaToolbar({ }: VexaToolbarProps) {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { post, getUser } = useApi();

  useEffect(() => {
    const user = getUser();
    if (!user?.token) {
      window.location.href = '/signin';
    } else {
      setIsAuthorized(true);
    }
  }, []);

  const signInUser = (credentials: CredentialResponse) => {
    sendMessage(MessageType.SIGNIN_STARTED);
    post('auth/google', { token: credentials.credential })
    .then(res => {
      const authURL = new URL(res.link);
      const vexaToken = authURL.searchParams.get('__vexa_token');
      window.location.href = `${process.env.REACT_APP_BASE_URL}?token=${vexaToken}`;
    }, error => {
      console.error(error);
      sendMessage(MessageType.SIGNIN_ERROR);
      toast('Login failed', { type: error });
    })
  }

  return <div className='VexaToolbar border-b border-b-[#1F242F] p-4 flex flex-row w-full'>
    <VexaLogo className='mr-auto' />
    {/* <AudioRecordingControlButton className='ml-auto h-auto' /> */}
    {!isAuthorized && <GoogleLogin
      onSuccess={credentialResponse => {
        signInUser(credentialResponse);
      }}
      onError={() => {
        toast('Login Failed', { type: 'error' });
      }}
      useOneTap
      auto_select
    />}
  </div>;
}
