import useStorage from "../shared/hooks/use-storage";

export class StorageService {
    private static readonly storage = window.localStorage;
    private static readonly listeners = new Map<string, Set<Function>>();

    /**
     * Sets a value in local storage.
     * @param key The key for the storage item.
     * @param value The value to store.
     * @param watchFn (Optional) A function to run whenever the value changes.
     * @returns A Promise that resolves to `undefined`.
     */
    static async set<T = any>(key: StoreKeys, value: T, watchFn?: (newValue: T, oldValue: T) => void): Promise<void> {
        const oldValue = StorageService.storage.getItem(key);
        StorageService.storage.setItem(key, JSON.stringify(value));
        if (watchFn && oldValue !== JSON.stringify(value)) {
            watchFn(value, oldValue ? JSON.parse(oldValue) : null as T);
        }
    }
  
    /**
     * Watches for changes in a specific key in local storage.
     * @param key The key to watch for changes.
     * @param watchFn The function to run whenever the value changes.
     * @returns A function to stop watching for changes.
     */
    static watchValueChange<T = any>(key: StoreKeys, watchFn?: (newValue: T, oldValue: T) => void): () => void {
      const storageChangeListener = (event: StorageEvent) => {
        if (event.storageArea === StorageService.storage && event.key === key) {
          const oldValue = JSON.parse(event.oldValue || 'null') as T;
          const newValue = JSON.parse(event.newValue || 'null') as T;
          if (watchFn && oldValue !== newValue) {
            watchFn(newValue, oldValue);
          }
        }
      };
  
      window.addEventListener('storage', storageChangeListener);
  
      return () => window.removeEventListener('storage', storageChangeListener);
    }
  
    /**
     * Stops watching for changes in a specific key in local storage.
     * @param key The key to stop watching.
     * @param watchFn (Optional) The specific watch function to remove. If not provided, all watch functions for the key will be removed.
     * @returns A boolean indicating success (always true in this case).
     */
    static unWatchValueChange<T = any>(key: StoreKeys, watchFn?: (newValue: T, oldValue: T) => void): boolean {
        if (!StorageService.listeners.has(key)) return true;

        const listeners = StorageService.listeners.get(key)!;
        for (const listener of listeners) {
            if (!watchFn || listener === watchFn) {
                window.removeEventListener('storage', listener as EventListener);
                listeners.delete(listener);
            }
        }

        if (listeners.size === 0) {
            StorageService.listeners.delete(key);
        }

        return true;
    }
  
    /**
     * Gets a value from local storage.
     * @param key The key for the storage item.
     * @param fallback (Optional) A default value to return if the key doesn't exist.
     * @returns The stored value or the fallback value if not found.
     */
    static async get<T = any>(key: StoreKeys, fallback?: T): Promise<T | undefined> {
      const item = StorageService.storage.getItem(key);
      return item ? JSON.parse(item) : fallback;
    }
  
    /**
     * Deletes a value from local storage.
     * @param key The key for the storage item to delete.
     */
    static delete(key: StoreKeys): void {
      StorageService.storage.removeItem(key);
    }
  
    /**
     * Clears all data from local storage.
     */
    static clear(): void {
      StorageService.storage.clear();
    }
  
    /**
     * A React hook to manage storage values in components.
     * @param key The key for the storage item.
     * @param onInit (Optional) A function to generate the initial value or a static value to render.
     * @returns A tuple containing the stored value and a function to update it.
     */
    static useHookStorage<T>(key: StoreKeys, onInit?: T) {
        return useStorage;
    }
}

export enum StoreKeys {
    USER = 'USER',
    FIRST_INSTALL = 'FIRST_INSTALL',
    RECORD_START_TIME = 'RECORD_START_TIME',
    SELECTED_MICROPHONE = "SELECTED_MICROPHONE",
    CAPTURING_STATE = "CAPTURING_STATE",
    CAPTURED_TAB_ID = "CAPTURED_TAB_ID",
    AUTHORIZATION_DATA = "AUTHORIZATION_DATA",
    MIC_LEVEL_STATE = "MIC_LEVEL_STATE",
    WINDOW_STATE = "WINDOW_STATE",
    RECORDER_INSTANCE = "RECORDER_INSTANCE",
    RECORDER_STREAM_INSTANCE = "RECORDER_STREAM_INSTANCE",
}

export interface AuthorizationData {
    token: string;
    domain: string;
  }