import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ApiProvider } from './shared/contexts/ApiContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ApiProvider
      baseURL={process.env.REACT_APP_MAIN_API_ENDPOINT || ''}
      initialAuthToken=""
    >
      <App />
      <ToastContainer />
    </ApiProvider>
  </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

// Store UTM into cookies
(function() {
  // Function to get the value of a URL parameter
  function getParameterByName(name) {
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(window.location.href);
    if (!results || !results[2]) return null;
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  // UTM parameters to capture
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

  // Iterate over the UTM parameters and store each in a cookie
  utmParams.forEach(param => {
    const value = getParameterByName(param);
    if (value) {
      // Set cookie (expires in 30 days)
      document.cookie = `${param}=${value}; path=/; max-age=${60 * 60 * 24 * 30};`;
    }
  });
})();
