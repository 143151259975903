import React, {useEffect, useState} from 'react';

import './Dashboard.scss';
import {ActionButton, TranscriptList} from '../TranscriptList';
import {VexaToolbar} from '../VexaToolbar';
import {Call, RecentCallsView} from '../RecentCallsView';
import {useApi} from '../../contexts/ApiContext';
import {onMessage} from '../../helpers/in-content-messaging.helper';
import {MessageType} from '../../../services/message-listener.service';
import {AssistantV2} from "../AssistantV2/AssistantV2";

export interface DashboardProps { }

export function Dashboard({ }: DashboardProps) {
  const { getUser } = useApi();
  const [selectedMeeting, setSelectedMeeting] = useState<Call>();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [actionButtonClicked, setActionButtonClicked] = useState<ActionButton>(null);
  const [assistantMessage, setAssistantMessage] = useState<string>(null);

  useEffect(() => {
    const user = getUser();
    if (user?.token) {
      setIsAuthorized(true);
    }
    const signinStartedCleanup = onMessage(MessageType.SIGNIN_STARTED, () => {
      setIsSigningIn(true);
    });
    const signinErrorCleanup = onMessage(MessageType.SIGNIN_ERROR, () => {
      setIsSigningIn(false);
    });
  }, []);

  function onActionButtonClicked(button: ActionButton) {
    setActionButtonClicked(button);
  }

  function onAssistantMessage(message: string) {
    setAssistantMessage(message);
  }

  return (
    <div className='Dashboard flex flex-col h-screen bg-slate-950 overflow-hidden'>
      < VexaToolbar />
      {isAuthorized ? <div className='ViewColumns text-[#F5F5F6] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 flex-1 overflow-hidden'>
        <div className="p-4 pr-0 pl-2 pt-0 mt-2 flex flex-col overflow-hidden">
          <p className="font-semibold pb-2">
            Recent calls
          </p>
          <RecentCallsView onMeetingSelected={(meeting) => setSelectedMeeting(meeting)} />
        </div>
        <div className="p-4 pl-2 pr-0 pt-0 mt-2 hidden sm:flex flex-col overflow-hidden">
          <p className="font-semibold pb-2">
            Transcript
          </p>
          <TranscriptList
            call={selectedMeeting}
            onActionButtonClicked={onActionButtonClicked}
            onAssistantRequest={onAssistantMessage}
          />
        </div>

        <div className="p-4  pl-2 pr-0 pt-0 mt-2 hidden md:flex flex-col overflow-hidden">
          <p className="font-semibold pb-2">
            Assistant
          </p>

          {selectedMeeting ? <AssistantV2
            call={selectedMeeting}
            key={selectedMeeting?.id}
            actionButtonClicked={actionButtonClicked}
            assistantMessage={assistantMessage}

            /> : null }
        </div>

        <div className="p-4  pl-2 pr-0 pt-0 mt-2 hidden lg:flex flex-col">
          {/* <p className="font-semibold pb-2">
            Note
          </p> */}
          {/* <NotesView /> */}
        </div>
      </div> : <div className='flex items-center justify-center gap-3 flex-col flex-grow'>
        <h3 className="text-white font-semibold">{isSigningIn ? 'Signing in...' : 'Login to start'}</h3>
      </div>}

      {/*<ThreadDeletePromptModal />*/}
    </div >
  );
}
